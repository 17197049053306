@tailwind base;
@tailwind components;
@tailwind utilities;

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 225deg;
}

html,
body {
  min-height: 100vh;
}

body {
  font-family: "Montserrat", sans-serif;
  background-image: linear-gradient(
    var(--a, 225deg),
    #fca962 0%,
    #fb8f74 41%,
    #fb6091 100%
  );
  background-repeat: no-repeat;
  background-size: 100vw;
  animation: spin 30s linear infinite;
}

@keyframes spin {
  0% {
    --a: -135deg;
  }
  100% {
    --a: 225deg;
  }
}
